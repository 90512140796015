import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { catchError, map, Observable, shareReplay, throwError } from 'rxjs';
import { Page } from '../../../@common/entities/page.interface';
import { ApiPath } from '../../../@common/http/api-path.enum';
import { Logger } from '../../../@common/log/logger';
import { Video } from '../../video/entities/video.interface';
import { Category } from '../entities/category.interface';

@Injectable({
    providedIn: 'root',
})
export class CategoryService {
    private http = inject(HttpClient);
    private logger = inject(Logger);

    getTopCategories(): Observable<Category[]> {
        return this.http.get<Page<Category>>(`${ ApiPath.category }/top_categories/`)
            .pipe(
                map(res => res.results ?? []),
                shareReplay(1),
                catchError(e => {
                    this.logger.apiError('Get top categories failed', e);
                    return [];
                })
            );
    }

    getAllCategories(
        limit: number,
        offset: number,
        sort: string,
        showLectures: boolean
    ): Observable<Page<Category>> {
        if (sort !== 'alphabet' && sort !== 'number_category') {
            sort = 'alphabet';
        }

        return this.http.get<Page<Category>>(
            `${ ApiPath.category }/${ sort }/?limit=${ limit }&offset=${ offset }&show_lectures=${ showLectures }&show_image=false`
        ).pipe(
            shareReplay(1),
            catchError(e => {
                this.logger.apiError('Get all categories failed', e);
                return throwError(() => e);
            })
        );
    }

    getLecturesOfCategoryViewAll(
        id: string,
        limit: number
    ): Observable<Page<Category>> {
        return this.http.get<Page<Category>>(`${ ApiPath.category }/${ id }/lectures/?limit=${ limit }/`)
            .pipe(
                shareReplay(1),
                catchError(e => {
                    this.logger.apiError('Get lectures of category failed', e);
                    return throwError(() => e);
                })
            );
    }

    getLecturesOfCategory(
        id: string,
        hits: string,
        language: string,
        limit: number,
        offset: number,
        sort: string,
        type: string,
        year: string
    ): Observable<Page<Video> & { category: string }> {
        let sortBy: string = '';
        if (sort === 'views') {
            sortBy = 'views';
        } else if (sort === 'published') {
            sortBy = 'published';
        } else if (sort === 'starred') {
            sortBy = 'starred';
        } else if (sort === 'title') {
            sortBy = 'title';
        } else {
            sortBy = '';
        }

        return this.http.get<Page<Video> & { category: string }>(
            `${ ApiPath.category }/${ id }/filter_lectures/?sort=${ sortBy }&hits=${ hits ?? '' }&type=${ type ?? '' }&year=${ year ?? '' }&language=${ language ?? '' }&limit=${ limit ?? '' }&offset=${ offset ?? '' }`
        ).pipe(
            shareReplay(1),
            catchError(e => {
                this.logger.apiError('Get lectures of category failed', e);
                return throwError(() => e);
            })
        );
    }

    getAllLecturesOfCategory(
        id: string,
        limit: number,
        offset: number,
        sort: string
    ): Observable<Page<Category>> {
        let sortBy: string = '';
        if (sort === 'alphabet') {
            sortBy = 'lectures_alphabetical';
        } else {
            sortBy = 'lectures_most_viewed';
        }
        return this.http.get<Page<Category>>(ApiPath.category + `/${ id }/${ sortBy }/?limit=${ limit }&offset=${ offset }`)
            .pipe(
                shareReplay(1)
            );
    }
}
